export const codeLoadingCss = `
#loading-bg {
    width: 100%;
    height: 100%;
    background: #FFF;
    display: block;
    position: absolute;
    z-index: 9;
}

.loading-logo {
    position: absolute;
    left: calc(50% - 345px);
    top: 38%;
    width: 100%;
    max-width: 705px;
}

.loading {
    position: absolute;
    left: calc(50% - 35px);
    top: 50%;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid transparent;
}

.loading .effect-1, .loading .effect-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgb(255, 121, 31);
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.loading .effect-1 {
    animation: rotate 1s ease infinite;
}

.loading .effect-2 {
    animation: rotateOpacity 1s ease infinite .1s;
}

.loading .effect-3 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid rgb(255, 121, 31);
    -webkit-animation: rotateOpacity 1s ease infinite .2s;
    animation: rotateOpacity 1s ease infinite .2s;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

}

.loading .effects {
    transition: all .3s ease;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes rotateOpacity {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        opacity: .1;
    }
    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
        opacity: 1;
    }
}
`

export const codeLoadingIndex =`
 <body>
    <noscript>
      <strong
        >We're sorry but Vuexy - Vuejs Admin Dashboard Template doesn't work properly without JavaScript enabled. Please
        enable it to continue.</strong
      >
    </noscript>
    <div id="loading-bg">
      <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
    <div id="app"></div>
  </body>
  
`

export const codeLoadingApp = `
<template>
  <div id="app">
    <div v-if="$store.state.app.isLoading" id="loading-bg" class="bs4" style="">
      <div class="loading-logo">
        <img src="./assets/images/textlogo.png" style="width: 100%"/>
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
    <div v-else>
      <Header/>
      <div class="site-body">
        <router-view/>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
mounted() {
    setInterval(this.isLoading, 500)
  },
  watch: {
    '$route' (to, from) {
      if(to.name !== from.name) {
        this.$store.commit('app/UPDATE_IS_LOADING', true)
        setInterval(this.isLoading, 500)
      }
    }
  },
    methods: {
    isLoading() {
      this.$store.commit('app/UPDATE_IS_LOADING', false)
    }
  }
</script>

`

export const codePropsSample = `
/* FirstSecton.vue */
<button-home
  title="Contact Us"
  skin="home-btn"
  @click="goSection( 'home', '#contact')" />
`
export const codeEmitSample = `
<!-- 하위 컴포넌트의 내용 -->
this.$emit('이벤트 명', '전달할 데이터');
 
 
<!-- 상위 컴포넌트의 템플릿 -->
<div id="app">
  <child-component v-on:이벤트 명="상위 컴포넌트의 실행할 메서드 명 또는 연산"></child-component>
</div>
`

export const codeCompositionAPI = `
  setup(props) {
    const state = reactive({
      buttonTitle: props.title,
      Skin: props.skin,
    })

    return toRefs(state)
  },
`

export const codeButtonFirstSectionComponent = `
/* FirstSection.vue */
<button-home
      title="Contact Us"
      skin="home-btn"
      @click="goSection( 'home', '#contact')" />

/* components/Button.vue */
<template>
  <div>
    <button
      :class="Skin"
      @click="goSectionEmit"
    >
      {{ buttonTitle }}</button>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    title: { type: String, default: '' },
    skin: { type: String, default: '' },
  },
  setup(props) {
    const state = reactive({
      buttonTitle: props.title,
      Skin: props.skin,
    })

    return toRefs(state)
  },
  methods: {
    goSectionEmit() {
      this.$emit('click')
    },
  },
}
</script>
`

export const codeButtonFifthSectionComponent = `
/* FifthSection.vue */
<button-send-msg
      title="Send Message"
      skin="sendMsg-btn"
      @click="sendMsg()" />

/* components/Button.vue */
<template>
  <div>
    <button
      :class="Skin"
      @click="goSectionEmit"
    >
      {{ buttonTitle }}</button>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    title: { type: String, default: '' },
    skin: { type: String, default: '' },
  },
  setup(props) {
    const state = reactive({
      buttonTitle: props.title,
      Skin: props.skin,
    })

    return toRefs(state)
  },
  methods: {
    goSectionEmit() {
      this.$emit('click')
    },
  },
}
</script>
`

export const codeButtonAboutComponent = `
/* NFT, Node, DeFi.vue */
<button-contact
      title="Contact Us"
      skin="contact-btn"
      @click="goSection( 'home', '#contact')" />

/* components/Button.vue */
<template>
  <div>
    <button
      :class="Skin"
      @click="goSectionEmit"
    >
      {{ buttonTitle }}</button>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    title: { type: String, default: '' },
    skin: { type: String, default: '' },
  },
  setup(props) {
    const state = reactive({
      buttonTitle: props.title,
      Skin: props.skin,
    })

    return toRefs(state)
  },
  methods: {
    goSectionEmit() {
      this.$emit('click')
    },
  },
}
</script>
`

export const codePropsSample2 = `
/* FirstSection.vue */
  <card-home
    h1="Node"
    h2="Staking"
    img="node"
    :contents="['Staking Tokens','Listing in Server','Long-term maintenance,\\nupdate the network']"
    href="/node"
    skin="node"
  />
`

export const codeCompositionAPI2 = `
/* CardHome.vue */
  setup(props) {
    const state = reactive({
      H1: props.h1,
      H2: props.h2,
      Img: props.img,
      Contents: props.contents.map(content => content.replace('\\n', '<br />')),
      Href: props.href,
      Skin: props.skin,
    })
    return toRefs(state)
  },
`

export const codeSlot = `
// 부모 컴포넌트
<foo>
  <div slot="slot 이름">{{ fooProps }}</div>
</foo>
 
// 자식 컴포넌트
<slot name="slot 이름" />
`

export const codeCardHome = `
<template>
  <div>
    <div
      class="home-card"
      :class="Skin"
    >
      <h1>{{ H1 }}</h1>
      <img
        :src="require(\`@/assets/images/{Img}.png\`)"
        alt="top"
      >
      <h2>{{ H2 }}</h2>
      <div
        class="card-text">
        <p
          v-for="(content, i) in Contents"
          :key="i"
          class="content"
          v-html="content"
        />
      </div>
      <a :href="Href">
        <button >View</button>
      </a>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    h1: { type: String, default: '' },
    h2: { type: String, default: '' },
    img: { type: String, default: '' },
    contents: { type: Array, default: () => [] },
    href: { type: String, default: '' },
    skin: { type: String, default: '' },
  },
  setup(props) {
    const state = reactive({
      H1: props.h1,
      H2: props.h2,
      Img: props.img,
      Contents: props.contents.map(content => content.replace('\\n', '<br />')),
      Href: props.href,
      Skin: props.skin,
    })
    return toRefs(state)
  },
}
</script>
`

export const codeCardNews = `
<template>
  <div>
    <div class="news-contents">
      <div class="news-box">
        <img
          src="@/assets/images/web-news.png"
          alt="newsImg"
        >
        <div class="news-top">
          <slot name="newsTitle" />
          <slot name="newsContent" />
        </div>
        <div class="text-overline">
          <slot name="newsDate" />
          <slot name="newsCompany" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
}
</script>
`

export const codeCardProfile = `
<template>
  <div class="card-row">
    <div
      v-for="(profile, i) in Profiles"
      :key="i"
      class="team-cards"
    >
      <img
        :src="require(\`@/assets/images/{profile.img}\`)"
        alt="profile"
      >
      <h5>{{ profile.name }}</h5>
      <p>{{ profile.career }}</p>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    profiles: { type: Array, default: () => [] },
  },
  setup(props) {
    const state = reactive({
      Profiles: props.profiles,
    })
    return toRefs(state)
  },
  methods: {
  },
}
</script>
`

export const codeForm = `
<template>
  <div>
    <form
      action=""
      method="get"
      class="contact-form"
    >
      <div
        v-for="(form, i) in Forms"
        :key="i"
        class="form-div"
      >
        <label :for="form.id">{{ form.label }}</label>
        <input
          :id="form.id"
          v-model="form.value"
          :type="form.type"
          :name="form.id"
          :placeholder="form.placeholder"
          required
        >
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    forms: { type: Array, default: () => [] },
  },
  setup(props) {
    const Forms = ref(props.forms)
    return { Forms }
  },
}
</script>
`

export const codePropsSample3 = `
 <form-contact-us :forms="forms" />
`

export const codeCompositionAPI3 = `
  setup(props) {
    const Forms = ref(props.forms)
    return { Forms }
  },
`

export const codeFormInputBinding = `
    <input
      :id="form.id"
      v-model="form.value"
      :type="form.type"
      :name="form.id"
      :placeholder="form.placeholder"
      required
    >
`